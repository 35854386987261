import { useContext, useState } from 'react'
import { emitCustomEvent } from 'react-custom-events'
import classnames from 'classnames/bind'
import { useMediaQuery } from '@react-hookz/web/esnext'
import { StoreContext } from '~/store'
import { ButtonDrawer, CharIcon, FormError, Icon, Modal, SendConfirmation } from '~/components'
import { filterOptions, getPackageText, getStatusText, getStatusClassName, getCarType, getOptionText, getReceiptStatusText, hasFixedPrice, hasTaxiAndEta, allowCancel, hasEnded } from '~/utils/data'
import { formatReadable, formatReadableDate, formatTime } from '~/utils/format'
import { getPdfReceipt, deleteBooking } from '~/lib/api'
import * as styles from './index.module.css'
import * as common from '~/config/common.module.css'
import { useTranslation } from 'react-i18next'
import i18next from '~/i18n'
import { track } from '../../utils/gtm'

const cx = classnames.bind(styles)

const getShortAddress = address => {
  if (!address) return '-'
  return `${address.streetName} ${address.streetNumber ? ' ' + address.streetNumber : ''}${address.entrance ? address.entrance : ''}, ${address.city}`
}

const formatDate = booking => {
  if (!hasEnded(booking) && booking.bookingStatus.vehiclePlate !== undefined && booking.bookingStatus.eta) {
    return i18next.t('info-arrives-apx') + ' ' + formatTime(new Date(booking.bookingStatus.eta));
  }
  return formatReadableDate(new Date(booking.pickUpTimeStamp))
}

const order = ['basePrice', 'fixedAdditionalFee', 'waitingFee', 'tip']

const renderPriceParts = booking => {
  const price = booking?.price

  let parts = []
  
  parts = parts.concat(price.priceComponents
    .filter(p => p.type !== 'fixedPrice')
    .sort((a, b) => {
      const indexA = order.indexOf(a.type)
      const indexB = order.indexOf(b.type)    
      if (indexA === -1 && indexB === -1) return 0
      else if (indexA === -1 && indexB > -1) return 1
      else if (indexA > -1 && indexB === -1) return -1
      return indexA - indexB
    })
    .map((p, index) => {
      return <span key={index}>{i18next.t('price_' + p.type)} {Math.round(p.amount)} kr<br/></span>
    })
  )

  if (price.vat) {
    parts.unshift(<span key="vat">{i18next.t('price_vat')} {price.vat.toString().replace('.', ',')} kr<br/></span>)
  }

  // parts.push(<span>Fullpris {Math.round(price.fullPrice)} kr<br/></span>)

  if (price.discount) {
    // parts.push(<span>Voucher {-Math.round(price.discount)} kr<br/></span>)
    parts.push(price.discountComponents.map((item, index) => {
      let title = item.name
      if (item.code) title += ': ' + item.code
      return <span key={index}><span className={styles.option}>{title}</span> {-Math.round(item.amount)} kr<br/></span>
    }))
  }

  return parts
}



export default function Booking({ booking, showDate, showBorder, onCancel }) {
  const { t } = useTranslation()
  const { user, refreshUser, paymentAccounts } = useContext(StoreContext)
  const [message, setMessage] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSendConfirmationOpen, setIsSendConfirmationOpen] = useState(false)
  const isPreBooking = booking.bookingType === 'PRE_BOOKING'
  const hasPrice = booking?.price
  const isDelivery = booking.serviceType === 'delivery'
  const isCreditCard = booking?.paymentStatus?.paymentMethod === 'CreditCard'
  const cardType = isCreditCard ? paymentAccounts?.find(account => account?.accountId === booking?.paymentStatus?.accountNumber)?.cardType : null
  const hasReceipt = booking?.receiptStatus === '100'
  const hasStops = booking.intermediateStopLocations?.length > 0
  const numStops = booking.intermediateStopLocations?.length
  const deliveryInfo = booking?.packageDeliveryInfo
  const flightInfo = booking.originLocation?.flightInfo
  const showAccount = booking?.account?.number
  const showTaxiInfo = hasTaxiAndEta(booking)  
  const destinationChar = hasStops ? 'ABCDE'[booking.intermediateStopLocations.length + 1] : 'B'
  const vehicleOptions = filterOptions(booking.vehicleOptions)
  const carType = getCarType(booking.serviceType)
  const isMobile = useMediaQuery('only screen and (max-width: 969px)')

  if (isMobile && hasEnded(booking)) showDate = false


  const cancelBooking = () => {
    setMessage(null)
    setIsLoading(true)
    // NOTE: clientBookingToken is only used for anonymous bookings
    deleteBooking(booking.id, booking?.clientBookingToken).then((response) => {
      refreshUser()
      emitCustomEvent('CANCEL_BOOKING', booking.id)
      track('cancelBooking', {bookingNumber:booking.id});
      setIsLoading(false)
      onCancel()
    })
      .catch((error) => {
        if (error?.response?.error === 'ALREADY_CANCELLED_BOOKING') {
          onCancel()
        }
        else {
          console.error(error)
          setMessage(error?.response?.message)
        }
        setIsLoading(false)
      })
  }

  const openReceipt = (e) => {
    e.stopPropagation()
    setMessage(null)

    getPdfReceipt(user.userId, booking.id)
      .then(res => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = `${booking.id}.pdf`
        anchor.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error(error)
        setMessage(error?.response?.message)
      })
  }

  // TODO: Use old JS for now, replace the ModalView with React in the future.
  const cancel = () => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      COPY.booking.receipt_confirm_cancel, [
        { title: COPY.booking.button_ok, callback: cancelBooking },
        { title: COPY.booking.button_cancel },
      ]
    )
  }

  const readMore = message => {
    APP.trigger(
      APP.events.SHOW_MODAL,
      message, [
        { title: COPY.booking.button_ok },
      ]
    )
  }

  const toggle = () => setIsOpen(!isOpen)

  const openSendConfirmation = () => {
    setIsSendConfirmationOpen(true)
  }

  const closeSendConfirmation = () => {
    setIsSendConfirmationOpen(false)
  }

  const buttons = [
    { func: openSendConfirmation, title: t('button-send-confirmation'), disabled: !allowCancel(booking) },
    { func: cancel, title: t('button-cancel-booking'), disabled: !allowCancel(booking), isLoading },
  ]

  const renderStops = (stops) => {
    return stops.map((item, index) => {
      return (
        <div key={index} className={cx(styles.stop, styles.intermediate)}>{getShortAddress(item)}</div> 
      )
    })
  }

  return (
    <li className={cx('order-item', styles.item, { loading: isLoading })}>

      {isSendConfirmationOpen && <Modal onClose={closeSendConfirmation} isOpen lightBackground absolute>
        <SendConfirmation bookingId={booking.id} onComplete={closeSendConfirmation} />
      </Modal>}

      <div className={styles.top} onClick={toggle}>
        <div className={cx(styles.stateIcon, styles[getStatusClassName(booking)])}></div>
        <div className={styles.stateStatus}>
          <div>{getStatusText(booking)}</div>
          <div className={common.smaller}>{carType?.title}{carType?.info && <span> - {carType?.info}</span>}</div>
        </div>
        <div className={styles.taxiInfo}>
          {(showTaxiInfo || showDate) && <div>{formatDate(booking)}</div>}
          {showTaxiInfo && <div className={common.smaller}>{booking.bookingStatus.vehiclePlate}</div>}
        </div>
        <div>
          {isOpen ? <Icon id="white-minus" /> : <Icon id="white-plus" />}
        </div>
      </div>

      <div className={cx(styles.bottom, { showBorder })}>
        <ul className="booking-notifications">
          {booking.originInfo && booking.originInfo.map((item, index) => (
            <li key={index} className="notification">
              <div className="message">{item}</div>
              <button className="text-button" onClick={() => readMore(item)}>{t('button-read-more')}</button>
            </li>
          ))}
        </ul>
        <div className={styles.bottomClickable} onClick={toggle}>
          <div className={cx(styles.stopsContainer, { hasStops })}>
            <div className={cx(styles.content, styles.departure, { hasStops })}>
              <CharIcon char="A" className={styles.charIcon} small />
              <div className={styles.stop}>{getShortAddress(booking.originLocation)}</div>
            </div>
            {hasStops && (isOpen || numStops === 1) && <div className={styles.stops}>{renderStops(booking.intermediateStopLocations)}</div>}
            {hasStops && !isOpen && numStops > 1 && <div className={styles.stops}>
              <CharIcon char={booking.intermediateStopLocations.length} className={styles.charIcon} small gray />
              <div className={styles.stop}>{booking.intermediateStopLocations.length} stopp</div>
            </div>}
            <div className={cx(styles.content, styles.destination, { hasStops })}>
              <CharIcon char={destinationChar} className={styles.charIcon} small />
              <div className={styles.stop}>{getShortAddress(booking.destinationLocation)}</div>
            </div>
          </div>
          <ul className={cx(styles.infoSection, { isClosed: !isOpen })}>
            <li className={styles.row}>
              {t('label-booking-number')}: <span className={styles.info}>{booking.id}</span>
            </li>
            {(isPreBooking || showDate) && <li className={styles.row}>
              {t('label-date')}: <span className={styles.info}>{formatReadable(new Date(booking.pickUpTimeStamp))}</span>
            </li>}
            {!isDelivery && <>
              <li className={styles.row}>
                {t('label-name')}: <span className={styles.info}>{booking?.traveler?.name}</span>
              </li>
              <li className={styles.row}>
                {t('label-phone')}: <span className={styles.info}>{booking?.traveler?.phoneNumber}</span>
              </li>
            </>}
            {flightInfo && <>
              <li className={styles.row}>
                {t('label-flight-number')}: <span className={styles.info}>{flightInfo.flightNumber}</span>
              </li>
              <li className={styles.row}>
                {t('label-baggage')}: <span className={styles.info}>{flightInfo.checkedInBaggage ? t('label-yes') : t('label-no')}</span>
              </li>
            </>}
            {hasPrice && <li className={styles.row}>
              <span>{t('label-price-information')}</span>: <span className={styles.info}>
                <span>{t('label-total')} {booking.price.finalPrice} kr</span>
                <span className={styles.infoParts}>{renderPriceParts(booking)}</span>
              </span>
            </li>}
            {isCreditCard && <li className={styles.row}>
              <span>{t('label-payment')}</span>: <span className={styles.info}>
                <span>{booking.paymentStatus.paymentMethodLabel}</span>
                <span className={styles.infoParts}>{cardType}</span>
              </span>
            </li>}
            <li className={styles.row}>
              {t('label-receipt')}: <span className={styles.info}>
                {hasReceipt && <button className={styles.receiptButton} onClick={openReceipt}>{t('button-download-receipt')}</button>}
                {!hasReceipt && getReceiptStatusText(booking)}
              </span>
            </li>
            {showAccount && <li className={styles.row}>
              <span>{t('label-account')}</span>: <span className={styles.info}>
                <span>{booking.account?.name}</span>
                <span className={styles.infoParts}>{booking.account?.number}</span>
              </span>
            </li>}
            {!isDelivery && <li className={styles.row}>
              {t('label-booking-options')}:
              {vehicleOptions?.length === 0 && <span className={styles.info}>-</span>}
              {vehicleOptions?.length > 0 && <ul className={styles.options}>
                {vehicleOptions.map((value, index) => <li key={index} className={styles.option}>{getOptionText(value)}</li>)}
              </ul>}
            </li>}
            {isDelivery && deliveryInfo && <>
              <li className={styles.row}>
                {t('title-extra-information')}: <span className={styles.info}>{deliveryInfo.otherInformation}</span>
              </li>
              <li className={styles.row}>
                <ul className={styles.options}>
                  {deliveryInfo.packages.map((value, index) => <li key={index} className={styles.option}>{getPackageText(value)}</li>)}
                </ul>
              </li>
              <li className={cx(styles.spacer)}></li>
              <li className={styles.row}>
                {t('label-name')}: <span className={styles.info}>{booking?.traveler?.name}</span>
              </li>
              <li className={styles.row}>
                {t('label-phone')}: <span className={styles.info}>{booking?.traveler?.phoneNumber}</span>
              </li>
              <li className={styles.row}>
                {t('label-name-on-door')}: <span className={styles.info}>{deliveryInfo.packageSender.nameOnDoor}</span>
              </li>
              {deliveryInfo.packageSender.doorCode && <li className={styles.row}>
                {t('label-door-code')}: <span className={styles.info}>{deliveryInfo.packageSender.doorCode}</span>
              </li>}
              {deliveryInfo.packageSender.numberOfStairs && <li className={styles.row}>
                {t('label-number-of-stairs')}: <span className={styles.info}>{deliveryInfo.packageSender.numberOfStairs}</span>
              </li>}
              <li className={cx(styles.spacer)}></li>
              <li className={styles.row}>
                {t('label-name-receiver-on-door')}: <span className={styles.info}>{deliveryInfo.packageReceiver.nameOnDoor}</span>
              </li>
              <li className={styles.row}>
                {t('label-phone-receiver')}: <span className={styles.info}>{deliveryInfo.packageReceiver.phoneNumber}</span>
              </li>
              {deliveryInfo.packageReceiver.doorCode && <li className={styles.row}>
                {t('label-door-code')}: <span className={styles.info}>{deliveryInfo.packageReceiver.doorCode}</span>
              </li>}
              {deliveryInfo.packageReceiver.numberOfStairs && <li className={styles.row}>
                {t('label-number-of-stairs')}: <span className={styles.info}>{deliveryInfo.packageReceiver.numberOfStairs}</span>
              </li>}
              <li className={styles.row}>
                {t('title-leave-outside')}: <span className={styles.info}>{deliveryInfo.packageReceiver.leaveOutsideDoor ? t('label-yes') : t('label-no')}</span>
              </li>
            </>}
            {booking.generalComment && <li className={styles.row}>
              {t('label-message')}: <span className={styles.info}>{booking.generalComment}</span>
            </li>}
          </ul>
        </div>
        <ButtonDrawer buttons={buttons} isLoading={isLoading} className={cx({ isClosed: !isOpen })}>
          {message && <div className={styles.error}>
            <FormError>{message}</FormError>
          </div>}
        </ButtonDrawer>
      </div>
    </li>
  )
}
